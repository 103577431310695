<template>
  <div>
    <div class="text-h2 text-center mt-4">
    {{greetingText}}
  </div>
  <div class="text-h4 text-center mt-4">
    {{subText}}
  </div>
  <div class="mt-7 ml-4">
    {{shopText}}
    <a href="https://familytablet.de">Familytablet.de</a>
  </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      greetingText: 'Hilfe & FAQ',
      subText: '',
      loginText: '',
      shopText: 'Weitere Infos bekommt ihr hier:'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  }
}
</script>
